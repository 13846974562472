export interface SiteDataProps {
  name: String;
  title: string;
  description: string;
}

const siteData: SiteDataProps = {
  name: "SendKit",
  // Your website's title and description (meta fields)
  title: "SendKit",
  description:
    "SendKit is a tool that streamlines sending newsletters to customers.",
};

export default siteData;
